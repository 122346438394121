.d-hidden {
  display: none;
}

.d-block {
  display: block;
}

.cart-container {
  position: relative;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  transition: all 0.5s ease;
}

.cart-container:hover {
  box-shadow: -6px 6px 15px rgba(0, 0, 0, 0.3);
  transform: scale(1.01);
}

.image {
  height: 600px;
}

.cart-container img {
  width: 100%;
}

.cart-container,
.cart-container img {
  border-radius: 10px;
  overflow: hidden;
  /* height: 700px; */
}

.lire {
  position: absolute;
  bottom: 40px;
  right: 40px;
  background-color: rgba(250, 235, 215, 0);
  height: 60px;
  width: 60px;
  border-radius: 50%;
  cursor: help;
  z-index: 10;
  animation: hide 0.5s ease;
  transition: all 0.5s;
}

.lire:hover {
  background-color: rgb(250, 235, 215);
  overflow: hidden;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  animation: show 0.5s ease;
}

.titre {
  z-index: 9;
  color: #d2d2d2;
  border-end-start-radius: 10px;
  border-end-end-radius: 10px;
  position: absolute;
  padding: 40px;
  bottom: 0px;
  width: 100%;
  transition: all 0.5s ease;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    0deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(0, 0, 0, 0.8) 35%,
    rgba(255, 255, 255, 0.2) 100%
  );
}

.lire:hover + .titre-container > .titre {
  bottom: 90%;
  padding-bottom: 0;
  background: rgba(0, 0, 0, 0);
  color: #333;
  width: auto;
  z-index: 11;
}

.description {
  visibility: hidden;
  opacity: 0;
  padding: 80px 40px;
  transition: opacity 1s linear;
}

.lire:hover > p {
  visibility: visible;
  opacity: 1;
}

.content {
  display: none;
}

.icone {
  color: #d2d2d2;
  margin: 50%;
  transform: translate(-50%, -50%);
}

.next-prev {
  position: absolute;
  top: 50%;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.next,
.prev {
  background-color: rgba(0, 0, 0, 0.2);
  color: rgba(255, 255, 255, 1);
  border: 2px solid rgba(255, 255, 255, 1);
  border-radius: 60%;
  margin: 0 10px;
  padding: 8px 10px;
  cursor: pointer;
  transition: all 0.2s ease-in;
}

.next:hover,
.prev:hover {
  background-color: rgba(200, 200, 200, 0.8);
}

.prev:disabled,
.next:disabled {
  background-color: rgba(100, 100, 100, 0.2);
  color: rgba(255, 255, 255, 0.3);
  border: 2px solid rgba(255, 255, 255, 0.3);
  cursor: no-drop;
}

.next i,
.prev i {
  font-size: xx-large;
}

@keyframes show {
  0% {
    bottom: 40px;
    right: 40px;
    height: 60px;
    width: 60px;
    border-radius: 50%;
  }
  100% {
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    border-radius: 10px;
  }
}

/* .video {
  padding-top: 50%;
} */

@keyframes hide {
  0% {
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    border-radius: 10px;
  }
  100% {
    bottom: 40px;
    right: 40px;
    height: 60px;
    width: 60px;
    border-radius: 50%;
  }
}
