.bg-black {
  background-color: black;
}

.large-cart {
  min-height: 100px;
  max-width: 70%;
  margin-inline: auto;
  border-radius: 5px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  padding: 20px 30px;
}

.text-container {
  border-left: 3px solid #888;
  border-radius: 2px;
  padding: 10px;
}

.cart-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.date {
  color: #888;
}

@media (max-width: 720px) {
  .large-cart {
    max-width: 100%;
  }
}
