.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;
}

/* .card-container {
  height: 600px;
} */

@media only screen and (max-width: 1080px) {
  .grid-container {
    display: flex;
    flex-direction: column;
  }
}
