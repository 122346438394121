.title {
  text-align: center;
  padding-bottom: 64px;
}

.loader-container {
  border: 2px solid rgba(100, 100, 100, 0.2);
  padding: 20px 40px;
  border-radius: 20px;
}
