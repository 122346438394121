.scrolled {
  position: fixed;
  animation: navAnimation 800ms ease-in-out forwards;
}

@keyframes navAnimation {
  0% {
    top: -100%;
    opacity: 0;
  }

  50% {
    top: 0;
    opacity: 0.4;
  }

  100% {
    top: 0;
    opacity: 1;
  }
}
