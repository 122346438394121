.gridContainer {
  display: flex;
  flex-wrap: wrap;
}

/* @media screen and (max-width: 1260px) { */
.categoryCart {
  max-width: 100%;
}
.subContainer {
  grid-row: 2/3;
}
.container {
  display: grid;
  grid-template-columns: 1fr;
}

.aside {
  display: block;
}
/* } */

.active {
  background: #1f615d;
  color: #fff;
}

.input:focus {
  outline: 2px solid rgba(31, 97, 93, 0.2);
  border: 1px solid rgba(0, 0, 0, 0);
  box-shadow: 2px 2px 5px rgba(31, 97, 93, 0.2);
  border-radius: 2px;
  outline-offset: 0;
}

.input {
  position: relative;
  width: 100%;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  transition: all 100ms ease-out;
}

.searchContainer {
  position: relative;
}

.form {
  margin-top: 0;
}

.search {
  background: #fff;
  border: none;
  transition: all ease-in 200ms;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0%, -50%);
}

.search:hover {
  color: #1f615d;
}
