.pannier {
  position: fixed;
  z-index: 1000;
  right: 0;
  width: 300px;
  height: 100vh;
  background-color: #f2f2f2;
  box-shadow: -5px 0 15px #666;
}

.closeButton {
  position: fixed;
  top: 20px;
  right: 20px;
  cursor: pointer;
  border: none;
  background: none;
  font-size: 25px;
  font-weight: 600;
  z-index: 1001;
}

.closeButton:active {
  transform: scale(0.8);
  transition: all ease-in-out 100ms;
}

.hidden {
  display: none;
}

.backDrop {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
}

.cardContainer {
  margin-top: 100px;
  padding: 10px;
}
