.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 184px;
  width: 100%;
}

.form {
  width: 90%;
  margin-inline: auto;
  grid-column: 1/3;
}

.inputFlex {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}

.textArea {
  resize: none;
}

.input,
.textArea,
.phoneNumber {
  width: 100%;
  padding: 10px;
  margin-bottom: 50px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  transition: all 100ms ease-out;
}
.phone {
  position: relative;
}

.region {
  position: absolute;
  top: 8px;
  left: 10px;
  color: #444;
}

.phoneNumber {
  padding-left: 60px;
}

.input:focus,
.textArea:focus {
  outline: 2px solid rgba(31, 97, 93, 0.2);
  border: 1px solid rgba(0, 0, 0, 0);
  box-shadow: 2px 2px 5px rgba(31, 97, 93, 0.2);
  border-radius: 2px;
  outline-offset: 0;
}

.button,
.annuler {
  background-color: #1f615d;
  border: none;
  padding: 20px 30px;
  width: 100%;
  color: #efefef;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
}

.button:disabled {
  cursor: no-drop;
  background-color: #909090;
}

.productsContainer {
  position: relative;
  background-color: #e6e6e6;
  padding: 40px 20px;
}

.title {
  display: flex;
  gap: 20px;
  align-items: center;
}

.annuler {
  background-color: #9b4419;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%);
  width: 80%;
}

.annuler:hover {
  background-color: #8b3c14;
}

.button:hover:enabled {
  background-color: #154441;
}

@media screen and (max-width: 1260px) {
  .container {
    display: flex;
    flex-wrap: wrap-reverse;
  }

  .productsContainer {
    width: 100%;
    min-height: 360px;
  }

  .carts {
    overflow-y: auto;
    max-height: 200px;
  }
}
