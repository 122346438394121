.imageHover,
.button {
  transition: all ease-out 400ms !important;
}
.containerProduct {
  border: 2px solid rgba(100, 100, 100, 0.1);
}

.skeletonCard {
  min-width: 300px;
  border: 1px solid #888;
}

@media screen and (max-width: 1260px) {
  .skeletonCard {
    width: 100% !important;
  }
}
