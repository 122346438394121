.description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.cart {
  border-radius: 10px;
  transition: all ease-in-out 200ms;
  cursor: pointer;
}

.cart:hover {
  transform: translateY(-10px);
  box-shadow: 1px 0 10px rgba(0, 0, 0, 0.2);
}
