.lodingPage {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lodingPageSecond {
  height: 30vh;
  width: 80vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.svg {
  width: 300px;
  overflow: visible;
  stroke: #fff;
  stroke-width: 3;
  stroke-linejoin: round;
  stroke-linecap: round;
}
