.backdrop {
  position: fixed;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100vw;
  height: 100vh;
}

.modalContainer {
  position: fixed;
  z-index: 10000;
  background-color: #e6e6e6;
  width: 50%;
  padding: 30px 20px;
  border-radius: 5px;
  left: 50%;
  top: 20%;
  transform: translate(-50%, 0);
  min-height: 300px;
  display: grid;
  gap: 20px;
}

/* .modalContainer button {
  position: absolute;
  bottom: 30px;
  right: 20px;
} */

.button {
  padding: 20px 0;
  background-color: #1f615d;
  border: none;
  border-radius: 5px;
  color: #efefef;
  cursor: pointer;
}

.button:hover {
  background-color: #164441;
}

@media screen and (max-width: 1260px) {
  .modalContainer {
    width: 80%;
  }
}
