.overlay {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.modal {
  position: fixed;
  height: 70%;
  width: 600px;
  z-index: 1000;
  background-color: #e6e6e6;
  top: 50%;
  left: 50%;
  transform: translate(50%, 50%);
  border-radius: 10px;
  /* overflow: hidden; */
}

.container {
  overflow-y: scroll;
  max-height: 70vh;
  background-color: inherit;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}

.ingredientContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.subItemContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media (max-width: 640px) {
  .modal {
    height: 80%;
    border-radius: 0;
    width: 100%;
  }
}

/* .container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
} */
