.pd-10 {
  padding-top: 8%;
  background-color: antiquewhite;
}

.article-container {
  background-color: antiquewhite;
}

.article-container2 {
  background-color: rgb(250, 240, 228);
}

.article-container h2 {
  text-align: center;
}

.image-container {
  position: relative;
}

.image {
  border: 5px solid #fff;
  aspect-ratio: 1/1;
  margin-left: 30px;
  margin-top: 80px !important;
  position: relative;
  z-index: 1;
}

.imagebg {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  aspect-ratio: 1/1;
  filter: blur(2px);
}
.name,
.post {
  width: fit-content;
  position: absolute;
  top: 30%;
  z-index: 2;
  right: 50px;
  background-color: #1f615d;
  padding: 5px 15px;
  color: antiquewhite;
}

.name {
  top: 40%;
}

.container,
.container2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding-bottom: 60px;
  gap: 20px;
}

.description {
  align-self: center;
}

.date {
  position: absolute;
  margin-top: 50px;
  background-color: rgba(9, 9, 9, 0.3);
  border-radius: 50px;
  padding: 5px 10px;
}

@media only screen and (max-width: 1190px) {
  .container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .container2 {
    display: flex;
    flex-direction: column-reverse;
    gap: 20px;
  }

  .container2 section {
    align-self: flex-start;
  }

  .title {
    padding-top: 80px;
  }

  .date {
    margin-top: 10px;
  }

  .image {
    width: 300px;
  }

  .imagebg {
    width: 300px;
  }

  .name,
  .post {
    left: 280px;
  }
}
