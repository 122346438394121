.modaleSkeleton {
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.modaleSkeleton div {
  border-radius: 10px;
}
