.card {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.card div {
  padding: 2px;
}

.cardData {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.textColored {
  color: #1f615d;
  font-size: 14px;
  font-weight: 700;
}

.button {
  background-color: #9b4419;
  border: none;
  border-end-end-radius: 10px;
  border-start-end-radius: 10px;
  width: 40px;
  cursor: pointer;
}

.button:hover {
  background-color: #8b3c14;
}
