.filter {
  height: 100%;
  width: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  z-index: 0;
}

.title {
  z-index: 1;
  position: relative;
}
